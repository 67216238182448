import { useDispatch, useSelector } from 'react-redux';

import {
  clearToastNotifications,
  pushToastNotification,
  removeToastNotification
} from 'dpl/shared/actions/toast_notifications';

export default function useToastNotifications() {
  const { toastNotifications } = useSelector(
    ({ toastNotifications: { notifications } }) => ({
      toastNotifications: notifications
    })
  );

  const dispatch = useDispatch();

  return {
    clearToastNotifications: () => dispatch(clearToastNotifications()),
    pushToastNotification: toast => dispatch(pushToastNotification(toast)),
    removeToastNotification: toast => dispatch(removeToastNotification(toast)),
    toastNotifications
  };
}
