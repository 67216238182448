import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  COMMON_FORM_FIELD_CLASSES,
  FORM_FIELD_VARIANT_TYPES
} from 'dpl/shared/form/utils/constants';

import Label from './Label';
import ErrorWrapper from './ErrorWrapper';

export const VARIANT_CLASSES = {
  [FORM_FIELD_VARIANT_TYPES.LARGE]: `${COMMON_FORM_FIELD_CLASSES} Textarea__input h4 pv4 ph4 fw-medium`,
  [FORM_FIELD_VARIANT_TYPES.REGULAR]: `${COMMON_FORM_FIELD_CLASSES} Textarea__input h4 pv2 ph2`
};

export default function SimpleTextarea({
  displayErrors,
  errors,
  id,
  label,
  labelProps,
  maxCharCount,
  name,
  noResize,
  onChange,
  required,
  value,
  placeholder,
  variant,
  newDesignSystemStyles,
  ...textareaProps
}) {
  const classes = classnames(VARIANT_CLASSES[variant], {
    'no-resize': noResize,
    'ph4 pv3 font-16 lh-body': newDesignSystemStyles
  });

  const textareaValue = value || '';
  const charCount = maxCharCount - textareaValue.length;

  return (
    <div className="Textarea">
      {(() => {
        if (label && maxCharCount) {
          return (
            <div className="flex justify-between">
              <Label
                htmlFor={id}
                required={required}
                text={label}
                newDesignSystemStyles={newDesignSystemStyles}
                {...labelProps}
              />
              <p
                className={classnames('f2 self-center', {
                  'light-silver': charCount >= 0,
                  red: charCount < 0
                })}
              >
                {charCount}
              </p>
            </div>
          );
        } else if (label) {
          return (
            <Label
              htmlFor={id}
              required={required}
              text={label}
              newDesignSystemStyles={newDesignSystemStyles}
              {...labelProps}
            />
          );
        }

        return null;
      })()}
      <ErrorWrapper isShown={displayErrors} errors={errors}>
        <textarea
          className={classes}
          id={id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={textareaValue}
          {...textareaProps}
        />
      </ErrorWrapper>
    </div>
  );
}

SimpleTextarea.propTypes = {
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
  label: PropTypes.string,
  labelProps: PropTypes.shape({
    className: PropTypes.string
  }),
  maxCharCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  noResize: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  newDesignSystemStyles: PropTypes.bool,
  placeholder: PropTypes.string,
  /** One of: "regular" or "large". */
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

SimpleTextarea.defaultProps = {
  displayErrors: false,
  errors: [],
  id: null,
  label: null,
  labelProps: {},
  maxCharCount: null,
  noResize: false,
  onChange: null,
  required: false,
  value: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR,
  newDesignSystemStyles: false,
  placeholder: null
};
