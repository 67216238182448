import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import SimpleTextarea from './SimpleTextarea';

export default function Textarea({
  onChange,
  name,
  labelProps,
  placeholder,
  newDesignSystemStyles,
  ...textareaProps
}) {
  const {
    formFieldErrors,
    formFieldId,
    formFieldValue,
    handleFormFieldChange
  } = useFormContext(name);

  return (
    <SimpleTextarea
      errors={formFieldErrors}
      id={formFieldId}
      name={name}
      placeholder={placeholder}
      onChange={onChange || handleFormFieldChange}
      value={formFieldValue}
      labelProps={labelProps}
      newDesignSystemStyles={newDesignSystemStyles}
      {...textareaProps}
    />
  );
}

Textarea.propTypes = {
  labelProps: PropTypes.shape({
    className: PropTypes.string
  }),
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  newDesignSystemStyles: PropTypes.bool,
  placeholder: PropTypes.string
};

Textarea.defaultProps = {
  labelProps: {},
  onChange: null,
  newDesignSystemStyles: false,
  placeholder: null
};
