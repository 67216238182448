import { useState } from 'react';

import { get, set, remove } from 'dpl/shared/utils/object';

export default function useFormState(initialState) {
  const [formState, setFormState] = useState(initialState);
  const [isDirty, setIsDirty] = useState(false);

  function reset(state = initialState) {
    setIsDirty(false);
    setFormState(state);
    return state;
  }

  function update(newState) {
    setIsDirty(true);
    setFormState(newState);

    return newState;
  }

  function setValue(path, value, oldFormState = formState) {
    const newState = set(oldFormState, path, value);
    return update(newState);
  }

  function batchSetValue(obj, oldFormState = formState) {
    return update(
      Object.entries(obj).reduce((newFormState, [path, value]) => {
        return set(newFormState, path, value);
      }, oldFormState)
    );
  }

  function removeValue(path, oldFormState = formState) {
    const currentValue = get(oldFormState, path, {});
    const isPersisted = Boolean(currentValue.id);
    if (isPersisted) {
      // if persisting record, mark for destroy
      return update(set(oldFormState, `${path}._destroy`, true));
    }

    // if non-persisting, remove from state
    return update(remove(oldFormState, path));
  }

  function pushValue(path, value = {}, oldFormState = formState) {
    const newIdx = get(oldFormState, path, [])?.length;
    if (newIdx === undefined) {
      throw new Error(
        'pushValue can be only used when existing value is array'
      );
    }
    return setValue(`${path}.${newIdx}`, value);
  }

  return {
    isDirty,
    formState,
    setValue,
    batchSetValue,
    removeValue,
    pushValue,
    reset
  };
}
