import { useState } from 'react';
import invariant from 'invariant';

import useToastNotifications from 'dpl/shared/hooks/useToastNotifications';
import { isPromise } from 'dpl/shared/utils';
import { isPlain } from 'dpl/shared/utils/object';
import { getStructuredServerErrorsFromResponseJson } from 'dpl/shared/utils/getStructuredServerErrorsFromResponseJson';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';

import { normalizeServerErrors } from '../utils';
import useValidation from './useValidation';
import useFormState from './useFormState';

const DEFAULT_OPTIONS = {
  defaultFormState: {},
  onSubmit: null,
  submitSuccessMessages: [],
  mapFormStateToValidationSchema: () => null
};

export default function useForm(opts = {}) {
  const options = { ...DEFAULT_OPTIONS, ...opts };

  const [serverErrors, setServerErrors] = useState({});
  const [didSubmissionFail, setDidSubmissionFail] = useState(false);
  const [didSubmissionSucceed, setDidSubmissionSucceed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    formState,
    setValue,
    removeValue,
    pushValue,
    reset,
    isDirty,
    batchSetValue
  } = useFormState(options.defaultFormState);

  const {
    isValidating,
    isValid,
    errors: clientErrors,
    validate
  } = useValidation(options.mapFormStateToValidationSchema);

  const [_setValue, _removeValue, _pushValue, _reset, _batchSetValue] = [
    setValue,
    removeValue,
    pushValue,
    reset,
    batchSetValue
  ].map(func => (...args) => {
    const newState = func(...args);
    validate(newState);
    setServerErrors({});
    setDidSubmissionFail(false);
    return newState;
  });

  const { pushToastNotification, clearToastNotifications, toastNotifications } =
    useToastNotifications();

  const formErrors = { ...clientErrors, ...serverErrors };

  function validateFormState() {
    validate(formState);
  }

  function handleFieldChange(e) {
    const { name, value } = e.target;
    return _setValue(name, value);
  }

  function handleSubmission(...args) {
    return new Promise((resolve, reject) => {
      invariant(
        typeof options.onSubmit === 'function',
        'handleSubmission was called without providing an onSubmit option'
      );

      if (isSubmitting) {
        window.console.warn(
          'handleSubmission called when request is already in flight'
        );
        resolve();

        return;
      }

      setIsSubmitting(true);

      const toastErrors = toastNotifications.filter(
        n => n.type === TOAST_NOTIFICATIONS_TYPES.ERROR
      );

      if (toastErrors.length > 0) {
        clearToastNotifications();
      }

      const ret = options.onSubmit(formState, ...args);

      isPromise(ret) ? ret.then(resolve).catch(reject) : resolve(ret);
    })
      .then(ret => {
        // backwards compatibility: in createFetchData we currently throw when
        // response.ok == false which is to replicate tpt-connect's behavior.
        // throwing w/ react-query is a bit more complex as it doesn't expose the
        // original promise (afaict). therefore we should stop throwing (as we
        // now do in createFetchData.*native*.js) and instead rely on the logic
        // below
        if (ret && 'response' in ret && !ret.response.ok) {
          const err = new Error('Form submission failed');
          err.response = ret.response;

          throw err;
        }

        options.submitSuccessMessages.forEach(e =>
          pushToastNotification({
            message: e,
            type: TOAST_NOTIFICATIONS_TYPES.SUCCESS
          })
        );

        setIsSubmitting(false);
        setDidSubmissionSucceed(true);

        return ret;
      })
      .catch(err => {
        setIsSubmitting(false);
        setDidSubmissionFail(true);

        if (
          'response' in err &&
          err.response.headers
            .get('content-type')
            .startsWith('application/json')
        ) {
          return err.response
            .clone()
            .json()
            .then(json => {
              if (json.errors) {
                // has validation errors grouped by field name
                setServerErrors(normalizeServerErrors(json.errors, formState));
              }

              // TODO: remove this once we've migrated all forms to use the above
              if (isPlain(json.error_messages)) {
                setServerErrors(
                  normalizeServerErrors(json.error_messages, formState)
                );
              }

              getStructuredServerErrorsFromResponseJson(json).forEach(message =>
                pushToastNotification({
                  message,
                  type: TOAST_NOTIFICATIONS_TYPES.ERROR
                })
              );

              return err;
            });
        }

        throw err;
      });
  }

  const contextValue = {
    setValue: _setValue,
    removeValue: _removeValue,
    pushValue: _pushValue,
    reset: _reset,
    batchSetValue: _batchSetValue,
    formErrors,
    formState,
    handleFieldChange,
    handleSubmission,
    isDirty,
    didSubmissionFail,
    didSubmissionSucceed,
    isSubmitting,
    isValidating,
    isValid,
    validate: validateFormState,
    setServerErrors,

    // backwards compatibility for attributes still used in form/components
    handleFormFieldChange: handleFieldChange,
    addToFormState: _setValue,
    pushToFormState: _pushValue
  };

  return {
    ...contextValue,
    contextValue
  };
}
