import { useState } from 'react';

import { isEmpty } from 'dpl/shared/utils/object';
import customValidate from 'dpl/shared/validations/customValidate';
import createFormSchema from 'dpl/shared/validations/createFormSchema';

export default function useValidation(mapFormStateToValidationSchema) {
  const [errors, setErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);

  /**
   * Addresses race-condition issues where validation relies on network and a
   * stale validation promise resolves after the most recent one
   */
  let _lastPromise;

  function validate(formState) {
    const schema = mapFormStateToValidationSchema(formState);

    if (!schema) {
      return Promise.resolve();
    }

    const formSchema = createFormSchema(schema);

    setIsValidating(true);

    const _thisPromise = customValidate
      .async(formState, formSchema, {
        fullMessages: false,
        // This is needed so that validate.js doesn't drop array constraints
        // whose keys are re-constructed subsequently w/in `runValidations` (see in
        // customValidate.js)
        cleanAttributes: false
      })
      .then(() => {
        if (_thisPromise === _lastPromise) {
          setIsValidating(false);
          setErrors({});
        }
      })
      .catch(_errors => {
        if (_thisPromise === _lastPromise) {
          setIsValidating(false);
          setErrors(_errors);
        }
      });

    _lastPromise = _thisPromise;

    return _lastPromise;
  }

  return {
    errors,
    isValidating,
    validate,
    isValid: isEmpty(errors)
  };
}
